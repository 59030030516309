<!--This is the Inventory page, with the list of all consignment inventory. -->
<template>
    <b-container fluid class="list-container py-4">
        <b-row class="mb-4">
            <b-col
                class="d-flex flex-column align-items-start justify-content-between flex-grow flex-sm-row align-items-sm-center"
            >
                <h1 class="text-primary-light mb-0">{{ t('sphericConsignmentInventory') }}</h1>
            </b-col>
        </b-row>

        <b-card>
            <b-row class="mb-2">
                <b-col xl="4" lg="5" class="h-80">
                    <h4 class="heavy mb-0">{{ t('inventoryList') }}</h4>
                    <p class="gray-dark mb-0">
                        {{ t('inventoryListDescription') }}
                    </p>
                </b-col>
            </b-row>
            <InventoryTable />
        </b-card>
    </b-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import InventoryTable from './components/InventoryTable.vue';

export default {
    name: 'InventoryList',
    components: {InventoryTable},
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            inventory: 'inventory/fetchInventory',
        }),
    },
    methods: {
        ...mapActions('inventory', ['clearList']),
        async refreshRecords() {
            await this.blockingRequest('inventory/fetchInventory');
        },
    },
    created() {
        this.clearList();
    },
    async mounted() {
        await this.refreshRecords();
    },
};
</script>
<style></style>
