<template>
    <div class="order-table-container px-0 mx-0">
        <b-table
            responsive
            fixed
            no-local-sorting
            id="order-table"
            class="table-list"
            :items="inventory"
            :fields="fields"
        >
            <template #table-colgroup="scope">
                <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{width: colWidths[field.key]}"
                />
            </template>
            <template #head(orderDate)="data">
                <div class="right-light-border">
                    <div class="d-flex column-explanation">
                        <div>
                            {{ data.label }}
                            <div class="mt-1 column-explanation-text">{{ t('dateHint') }}</div>
                        </div>
                    </div>
                </div>
            </template>
            <template #head(createdBy)="data">
                <div class="content">
                    <div class="d-flex column-explanation">
                        <div>
                            {{ data.label }}
                            <div class="mt-1 column-explanation-text">
                                {{ t('patientNameLastFirst') }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #cell(status)="{value, item}">
                <div class="d-flex">
                    <b-badge pill :variant="getStatusBadgeColor(item.statusId)">
                        {{ value }}
                    </b-badge>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import {formatLensNum} from '@/utilities/formatters';
import {ConsignmentLensStatuses} from '@/constants/inventory';

export default {
    components: {},
    props: {
        queryParams: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            colWidths: {
                //based on a 1536px width resolution screen
                lensId: '200px',
                model: '200px',
                version: '200px',
                sphere: '200px',
                expiration: '200px',
                status: '200px',
            },
        };
    },
    computed: {
        ...mapGetters('user', ['currentUser']),
        ...mapState({
            inventory: (state) => state.inventory.inventoryList,
        }),
        fields() {
            return [
                {key: 'lensId', stickyColumn: true, label: this.t('serialNo')},
                {key: 'model', label: this.t('invLookupCol_Model')},
                {key: 'version', label: this.t('invLookupCol_Version')},
                {
                    key: 'sphere',
                    label: this.t('invLookupCol_Sphere'),
                    formatter: (value) =>
                        this.formatLensNum(value, 2, this.currentUser.decimalSeparator) + ' D',
                },
                {key: 'expiration', label: this.t('expiration')},
                {key: 'status', label: this.t('statusNormalCase')},
            ];
        },
    },
    methods: {
        formatLensNum,
        getStatusBadgeColor(statusId) {
            switch (statusId) {
                case ConsignmentLensStatuses.AVAILABLE:
                    return 'success';
                case ConsignmentLensStatuses.RESERVED:
                    return 'warning';
                case ConsignmentLensStatuses.CONSUMED:
                    return 'primary';
                default:
                    return 'dark';
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';

::v-deep .light-border-right {
    border-right: 1px solid $gray-light;
}
</style>
